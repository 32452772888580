import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Tooltip, Avatar, Image } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { Link, useParams } from "react-router-dom";

import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import AddForm from "../Venue/AddForm";
import ImportForm from "../../components/ImportForm";

import ConfirmationBox from "../../components/ConfirmationBox";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import lang from "../../helper/lang";
import ConfirmationDelete from "../../components/ConfirmationDelete";

const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
  const permission= userProfile.user_type== "SubAdmin"?userProfile.permission.VenueSection:undefined;

  const sectionName = lang("Venue");
  const routeName = "venue";

  const api = {
    status: apiPath.statusVenue,
    delete: apiPath.deleteVenue,
    addEdit: apiPath.addEditVenue,
    list: apiPath.listVenue,
    importFile: apiPath.importVenue
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const { confirmDelete } = ConfirmationDelete()

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [confirm, setShowConfirm] = useState(false)
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  const activity = (id) => {
    navigate(`/user/activity/${id}`)
  }

  const columns = [
    {
      title: `${lang("Venue Image")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { logo, image }) => {
        return <Image className="image-index-radius" src={ logo? logo: image} />
      },
    },
    {
      title: `${lang("Venue Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id }) => {
        return (<a className="cap avatar-text" >{name ? name : 'Not Available'}</a>);
      },
      sorter: (a, b) => {
        let nameA = a.name?.toLowerCase();
        let nameB = b.name?.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: `${lang("Featured")}`,
      key: "is_featured",
      filters: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      render: (_, { is_featured, _id }) => {
        let color = is_featured ? 'green' : 'red';
        const type = "is_featured";
        return (<a><Tag color={color} key={is_featured}>{is_featured ? "Yes" : "No"}</Tag></a>);
      },
    },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        return (
          <a>
            <Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag>
          </a>
        );
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
      sorter: (a, b) => {
        let nameA = a.created_at;
        let nameB = b.created_at;
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    ...(userProfile.user_type=="Admin" ||permission?.includes("view") ? [ 
      {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
           {userProfile.user_type=="Admin"|| permission?.includes("edit")? <Tooltip title={`${lang('Update')} ` + sectionName} color={"purple"} key={"update" + routeName}>
           <Link className="ant-btn ant-btn-default" title="Edit" to={`/edit-venue/` + (record ? record._id : null)}><i className="fa fa-light fa-pen"></i>
           </Link>
            </Tooltip>:""}
            {userProfile.user_type=="Admin"|| permission?.includes("edit")?  <Tooltip title={`${lang('Delete')} ` + sectionName} color={"purple"} key={"Delete" + routeName}>
            <Button title="Delete" onClick={() => confirmDelete({ record: record._id, path: api.delete, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>
                <i className="fas fa-trash"></i>
              </Button>
            </Tooltip>:""}

          </>
        );
      },
    },]:[])
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText, startDate, endDate])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    const filterVerify = filters ? filters.is_verify : null;
    const filterFeatured = filters ? filters.is_featured : null;


    request({
      url: api.list + `?verify=${filterVerify ? filterVerify.join(',') : ''}&featured=${filterFeatured? filterFeatured.join(','): ''}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    } else {
      setStartDate()
      setEndDate()
    }
  };

  const excelData = list.map(row => ({
    "Name": row.name ? row.name : '-',
    "Music Type": row.music_type ? row.music_type : '-',
    "Opening Time": row.start_time ? moment(row.start_time, 'HH:mm').format('h:mm a') : '-',
    "Closing Time": row.end_time ? moment(row.end_time, 'HH:mm').format('h:mm a')   : '-',
    "Featured": row.is_featured ? "Yes" : 'No',
    "Status": row.is_active ? 'Active' : 'Inactive',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY")
  }));

  return (
    <>
      <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <div className="searchOuter">
                    <Search className="mainSearch"
                      size="large"
                      allowClear
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder={`${lang("Search")} ${lang("By")} ${lang("Venue")} ${lang("Name")}`}
                    />
                  </div>
                 {userProfile.user_type=="Admin"|| permission?.includes("add")? <div className="button_group">
                    <Button onClick={(e) => { navigate("/add-venue")}}>{lang("Add")} {sectionName}</Button>
                    <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button>
                    {/* <Button title="Import" onClick={(e) => { setImportVisible(true); setSearchText(''); }}><i className="fas fa-cloud-upload-alt"></i>&nbsp;&nbsp;Import</Button> */}
                  </div>:""}
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ 
                    ...pagination,
                    defaultPageSize: 10,
                     responsive: true, 
                    //  total: pagination.total, 
                     showSizeChanger: true, 
                     pageSizeOptions: ['10', '20', '30', '50'] 
                    }}                 onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {visible && <AddForm type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      {confirm && (
        <ConfirmationDelete
          title={"Delete Venue"}
          subtitle={`Are you sure you want to Delete this venue?`}
          show={confirm}
          hide={() => {
            setShowConfirm(false);
            setSelected();
          }}
          path = {api.delete}
          data={selected}
          refresh={() => setRefresh(prev => !prev)}        />
      )}
      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}

    </>
  );
}
export default Index;
