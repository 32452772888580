import { Row, Col, Card, Table, Button, Input, Tag, DatePicker, Tooltip, Avatar, Image } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import moment from 'moment';
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import AddForm from "../Event/AddForm";
import ImportForm from "../../components/ImportForm";
import ConfirmationBox from "../../components/ConfirmationBox";
import ConfirmationDelete from "../../components/ConfirmationDelete";
import { DownloadExcel, SampleFileDownload } from "../../components/ExcelFile";
import lang from "../../helper/lang";
import EventGuest from "../GuestList/Index";
import TableView from "../Table/View";

const Search = Input.Search;
const { RangePicker } = DatePicker;

function Index() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
  const sectionName = lang("Group");
  const routeName = "groups";
  const permission= userProfile.user_type== "SubAdmin"?userProfile.permission.GroupSection:undefined;

  const api = {
    status: apiPath.statusEvent,
    delete: apiPath.deleteGroup,
    addEdit: apiPath.addEditEvent,
    list: apiPath.listEvent,
    importFile: apiPath.importEvent,
    tableList: apiPath.tableList
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const { confirmDelete } = ConfirmationDelete()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [visible, setVisible] = useState(false);

  const [tableView, setTableView] = useState(false);
  const [eventName, setEventName] = useState();
  const [guestView, setGuestView] = useState(false);
  const [importVisible, setImportVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  console.log(permission, "jdskd")
 
  const columns = [
    {
      title: `${lang("Group Image")}`,
      dataIndex: "image",
      key: "image",
      render: (_, { cover_image, group_name }) => {
        return (cover_image?<Image className="image-index-radius" src={cover_image} />: <Avatar className="cap" size={40}>{group_name.charAt(0)}</Avatar>)
      },
    },
    {
      title: `${lang("Group Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { group_name, _id }) => {
        return (<a className="cap avatar-text" >{group_name ? group_name : 'Not Available'}</a>);
      },
      sorter: (a, b) => {
        let nameA = a.group_name?.toLowerCase();
        let nameB = b.group_name?.toLowerCase();
        if (nameA < nameB)
          return -1;
        if (nameA > nameB)
          return 1;
        return 0;
      },
    },
    {
      title: `${lang("Group Description")}`,
      dataIndex: "description",
      key: "description",
      render: (_, { group_intro, _id }) => {
        return (<a className="cap avatar-text" >{group_intro ? group_intro : 'Not Available'}</a>);
      },
    },
    {
        title: `Group Members`,
        dataIndex: "venue_id",
        key: "venue_id",
        render: (_, { friend_id }) => {
          return (<a className="cap avatar-text">{friend_id ? friend_id.length : 'Not Available'}</a>);
        }
      },
      {
        title: `Joined Events`,
        dataIndex: "events",
        key: "events",
        render: (_, { events }) => {
          return (<a className="cap avatar-text">{events ? events.length : 'Not Available'}</a>);
        }
      },
    {
      title: `${lang("Specialities")}`,
      dataIndex: "venue_id",
      key: "venue_id",
      render: (_, { specialities }) => {
        return (<a className="cap avatar-text">{specialities.length>0 ? specialities.map((name) => name).join(', ') : '-'}</a>);
      }
    },
     {
      title: `Drink Preference`,
      dataIndex: "drink_preference",
      key: "drink_preference",
      render: (_, { drink_preference, _id }) => {
        return (<a className="cap avatar-text" >{drink_preference.length>0? drink_preference.map((name)=> name).join(','):"-"}</a>);
      },
    },
    {
        title: `Joining Requirements`,
        dataIndex: "joining_requirements",
        key: "joining_requirements",
        render: (_, { joining_requirements, _id }) => {
          return (<a className="cap avatar-text" >{joining_requirements.length>0? joining_requirements.map((name)=> name).join(','):"-"}</a>);
        },
      },
      {
        title: `Cancellation Policy`,
        dataIndex: "cancellation_policy",
        key: "cancellation_policy",
        render: (_, { cancellation_policy, _id }) => {
          return (<a className="cap avatar-text" >{cancellation_policy? "Accepted":"--"}</a>);
        },
      },
    {
      title: `${lang("Status")}`,
      key: "is_active",
      filters: [
        {
          text: 'Active',
          value: true,
        },
        {
          text: 'Inactive',
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        const type = "is_active";
        return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: apiPath.groupStatus, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: `Created on`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
      
    },
    {
      title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            { userProfile.user_type=="Admin" ||permission?.includes("edit") ?  <Tooltip title={`${lang('Delete')} ` + sectionName} color={"purple"} key={"update" + routeName}>
              {/* <Button title="Edit" onClick={() => {
                navigate(`/event/edit/${record._id}`)
                setSelected(record)
                setVisible(true)
              }}>
                <i className="fa fa-light fa-pen"></i>
              </Button> */}
              <Button title="Delete" onClick={() => confirmDelete({ record: record._id, path: api.delete, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>
                <i className="fas fa-trash"></i>
              </Button>
            </Tooltip>: ""}
            <Tooltip title={`${lang('View')} ` + sectionName} color={"purple"} key={"view" + routeName}>
              <Button title="View" onClick={() => view(record._id)}>
                <i className="fa fa-light fa-eye"></i>
              </Button>
            </Tooltip>
           
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)

  }, [refresh, debouncedSearchText, startDate, endDate])

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null
    const filterVerify = filters ? filters.is_verify : null;

    request({
      url: apiPath.groupList + `?verify=${filterVerify ? filterVerify.join(',') : ''}&status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}&start_date=${startDate ? startDate : ""}&end_date=${endDate ? endDate : ""}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }
  

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
      setPagination({ current: 1 })

    } else {
      setStartDate()
      setEndDate()
      setPagination({ current: 1 })

    }
  };

  const excelData = list.map(row => ({
    "Group Name": row.group_name ? row.group_name : '-',
    "Group Description": row.group_intro ? row.group_intro : '-',
    "Group Members": row.friend_id ? row.friend_id.length : '-',
    "Joined Events": row.events ? row.events.length : '-',
    "Specialities": row.specialities ? row.specialities.map((name) => name).join(', ') : '-',
    "Drink Preference": row.drink_preference ? row.drink_preference.map((name)=> name).join(',') : '-',
    "Joining Requirements": row.joining_requirements ? row.joining_requirements.map((name)=> name).join(',') : '-',
    "Cancellation Policy": row.cancellation_policy ? "Accepted" : '-',
    "Status": row.is_active ? 'Active' : 'Inactive',
    "Registered On": moment(row.created_at).format("DD-MM-YYYY")
  }));
  return (
    <>
      <div className="tabled customerMain">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <div className="searchOuter">
                    <Search className="mainSearch"
                      size="large"
                      allowClear
                      onChange={onSearch}
                      value={searchText}
                      onPressEnter={onSearch}
                      placeholder={`${lang("Search")} ${lang("By")} ${lang("Group")} ${lang("Name")}`}
                    />
                    <RangePicker disabledDate={(current) => current.isAfter(Date.now())} onChange={handleChangeDate} />
                  </div>
                  {userProfile.user_type=="Admin" || permission.includes("add")?<div className="button_group">
                    <Button title="Export" onClick={(e) => DownloadExcel(excelData, sectionName)}><i className="fas fa-cloud-download-alt"></i>&nbsp;&nbsp;{lang("Export")}</Button>
                     </div>: ""}
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ 
                    ...pagination,
                    defaultPageSize: 10,
                     responsive: true, 
                    //  total: pagination.total, 
                     showSizeChanger: true, 
                     pageSizeOptions: ['10', '20', '30', '50'] 
                    }}
                                     onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
 {/* {confirm && (
        <ConfirmationDelete
          title={"Delete Venue"}
          subtitle={`Are you sure you want to Delete this venue?`}
          show={confirm}
          hide={() => {
            setShowConfirm(false);
            setSelected();
          }}
          path = {api.delete}
          data={selected}
          refresh={() => setRefresh(prev => !prev)}        />
      )} */}
      {visible && <AddForm type={sectionName} path={api.addEdit} sectionName={sectionName} show={visible} hide={() => { setSelected(); setVisible(false) }} data={selected} refresh={() => setRefresh(prev => !prev)} />}
      {tableView && <TableView
              title = {`${eventName} event tables`}
              path = {selected}
              show = {tableView}
              hide={() => { setSelected(); setTableView(false) }}
              refresh={() => setRefresh(prev => !prev)}
              />}
              {guestView && <EventGuest
              title = {`${eventName} event guests`}
              path = {selected}
              show = {guestView}
              hide={() => { setSelected(); setGuestView(false) }}
              refresh={() => setRefresh(prev => !prev)}
              />}
      {importVisible && <ImportForm path={api.importFile} sectionName={sectionName} show={importVisible} hide={() => setImportVisible(false)} existingData={list} refresh={() => setRefresh(prev => !prev)} />}

    </>
  );
}
export default Index;
