import moment from "moment";
import React, { useState, useEffect } from "react";

import Delete from "../assets/images/delete.svg";
import useRequest from "../hooks/useRequest";
import { ShowToast, Severty } from "../helper/toast";

import notification from "../assets/images/icon/notification.svg";
import { Button } from "antd";
import apiPath from "../constants/apiPath";

const RecentNotificationItem = ({ item, deleteNotification, refresh }) => {
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)

  const handleAccept = (data) => {
    console.log(data, 'datta>>>>>>..')
    request({
      url: apiPath.acceptCommission + data,
      method: 'POST',
      onSuccess: (data) => {
        setLoading(false);
        refresh();
        },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })

  }

  const handleReject = (data) => {
    request({
      url: apiPath.rejectCommission + data,
      method: 'POST',
      onSuccess: (data) => {
        setLoading(false)
        refresh();
        },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })

  }

  return (
    <div className="notification-inner">
      <div className="notification-img">
        <img src={notification} />
      </div>
      <div className="notification-cont">
        <div className="notification-left">
          <h4> {item.title} </h4>
          <p> {item.description} </p>
          <div className="header-notfy-bt">
          {
            item.data.type == "maleGuestCommission"||item.data.type == "femaleGuestCommission"||item.data.type == "tableCommission"||item.data.type == "ticketCommission"?
           <>
            <Button onClick={()=> handleAccept(item._id)}>Accept</Button>
            <Button onClick={()=> handleReject(item._id)}>Reject</Button>
           </>: ""
          }
          <small>
            {" "}
            {item.created_at
              ? moment(item.created_at).format("DD-MM-YYYY")
              : ""}{" "}
          </small>
          </div>
        </div>

        <div className="notification-right">
          <a onClick={(e) => deleteNotification(item._id)}>
          <i
            className="fas fa-trash"
            style={{ color: "white", fontSize: "16px" }}
            onCLick={() => {}}
          ></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default RecentNotificationItem;
