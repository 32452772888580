import { Row, Col, Radio, Modal, Image, Button, Form, Input, Select, InputNumber } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
import LocationMap from "./LocationMap";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { UploadOutlined } from '@ant-design/icons';
import { uploadFile } from 'react-s3';
import MultipleImageUpload from "../../components/MultipleImageUpload";
import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import { DRIVER_TYPE, USERTYPE } from "../../constants/conts";
import lang from "../../helper/lang";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Option } from "antd/lib/mentions";

const UserFrom = ({ type, path, sectionName, show, hide, data, refresh }) => {

  const api = {
    userList: apiPath.allUserList,
  }

  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"];
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [latLong, setlatLong] = useState({ lat: 30.5595, lng: 22.9375 });
  const [location, setLocation] = useState();
  const [mobileNumber, setMobileNumber] = useState({ mobile: '', country_code: '' });
  const [userAddress, setUserAddress] = useState(null);
  const [permissions, setPermissions] = useState();
  const [tradeLicense, setTradeLicense] = useState([]);
  const [subAdmins, setSubAdmins] = useState([]);
  const [license, setLicense] = useState([]);
  const [otherDocument, setOtherDocument] = useState([]);

  const handleChange = (value, data) => {
    var country_code = data.dialCode;
    setMobileNumber({ country_code: country_code, mobile: value.slice(data.dialCode.length) });
  }

  const handleTradeLicenseImage = (data) => {
    setTradeLicense(data[0]?.url);
  }

  const handleLicenseImage = (data) => {
    setLicense(data[0]?.url);
  }

  const handleOtherDocument = (data) => {
    setOtherDocument(data);
  }

  const handleOtherDocumentDelete = (data) => {
    setOtherDocument(prevFileList => prevFileList.filter(item => item.url !== data));
  }

  const handleLocationChange = (value) => {
    setLocation(value);
  }

  const getSubAdmins = () => {
    request({
      url: apiPath.subAdmins,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) return;
        setSubAdmins(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }

  useEffect(() => {
    getSubAdmins();
    getVendorList();
    getPermissions();
    if (!data) return;
    form.setFieldsValue({ ...data });
    setTradeLicense(data.trade_license_image);
    setLicense(data.license_image);
    // setlatLong({ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) });
    setOtherDocument(data.other_document && data.other_document.length > 0 && data.other_document.map(item => ({ url: item })));
    setLocation({
      location: data?.location,
      latitude: data?.latitude,
      longitude: data?.longitude,
      city: data?.city,
      country: data?.country,
      postal_code: data?.postal_code,
    })
    // setUserAddress(data.location);
    setMobileNumber({
      mobile: data.mobile_number,
      country_code: data.country_code
    })
  }, [data])

  const onCreate = (values) => {
    if (!mobileNumber.mobile) return ShowToast('Please enter mobile number', Severty.ERROR)
    if (((mobileNumber.mobile).length < 8 || (mobileNumber.mobile).length > 12)) {
      return ShowToast('Mobile number should be between 8 to 12 digits', Severty.ERROR);
    }

    setLoading(true);
    const payload = {
      ...values,
      country_code: mobileNumber.country_code,
      mobile_number: mobileNumber.mobile,
      location: location?.location,
      latitude: location?.latitude,
      longitude: location?.longitude,
      license_image: license?.length ? license : '',
      trade_license_image: tradeLicense?.length ? tradeLicense : '',
      other_document: otherDocument ? [...otherDocument?.map((item) => item.url)] : []
    };

    request({
      url: `${data ? path + "/" + data._id : path}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    })
  };

  const getVendorList = () => {
    request({
      url: api.userList,
      method: 'POST',
      data: { type: USERTYPE.VENDOR },
      onSuccess: (data) => {
        setVendorList(data.data);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  };

  const getPermissions = () => {
    request({
      url: apiPath.profile,
      method: 'GET',
      onSuccess: (data) => {
        setPermissions(data.data.permission);
      },
      onError: (error) => {
        console.log(error)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  return (
    <Modal
      width={1100}
      open={show}
      title={`${data ? lang("Update") + " " + sectionName : lang("Add") + " " + lang("New") + " " + sectionName}`}
      okText={`${data ? lang("Update") : lang("Add")}`}
      cancelText={
        <>
          <CloseOutlined style={{ marginRight: '8px' }} />
          {lang("Cancel")}
        </>
      }
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
        disabled: permissions && !permissions?.CustomerSection?.includes('add'),
        title: !permissions?.CustomerSection?.includes('add') ? 'Insufficient Permission' : '',
        icon: < CheckOutlined />,
      }}
    >
      <Form id="create" form={form} initialValues={{ deal_in: "Products" }} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} lg={8} sm={12}>
            <Form.Item className="mb-1"
              label="Name"
              name="name"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the name!" },
                { max: 200, message: "Name should not contain more then 200 characters!" },
                { min: 2, message: "Name should contain at least 2 characters!" },
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Name" />
            </Form.Item>
          </Col>

          <Col span={24} lg={8} sm={12}>
            <Form.Item className="mb-1"
              label="Email Address"
              name="email"
              rules={[
                { type: 'email', message: 'The email is not a valid email!' },
                { required: true, message: "Please enter the email!" },
                { max: 500, message: "Email should not contain more then 500 characters!" },
                { min: 5, message: "Email should contain at least 5 characters!" },
                {
                  pattern: new RegExp(/^([a-zA-Z0-9._%-]*[a-zA-Z]+[a-zA-Z0-9._%-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/),
                  message: "Enter valid email!"
                }
              ]}
            >
              <Input autoComplete="off" placeholder="Enter Email Address" />
            </Form.Item>
          </Col>

          <Col span={24} lg={8} sm={12} className="flagMobileNumber">
            <Form.Item className="mb-1"
              label="Mobile Number">
              <PhoneInput inputProps={{
                name: "mobile",
                required: true,
                autoFocus: false,
                placeholder: "Enter Mobile Number"
              }}
                isValid={(value, country) => {
                  if (value.match(/1234/)) {
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else if (value.match(/1234/)) {
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else {
                    return true;
                  }
                }}
                country={"ca"} value={mobileNumber ? ((mobileNumber.country_code ? mobileNumber.country_code : "+1") + (mobileNumber.mobile ? mobileNumber.mobile : null)) : '+1'} onChange={handleChange} />
            </Form.Item>
          </Col>
          {
            type == "Customer"? 
            <Col span={24} lg={8} sm={12}>
            <Form.Item className="mb-1"
              label="Gender"
              name="gender"
              
            >
<Select showSearch placeholder="Select gender" filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}>
                    <Select.Option key={"Female"} value={"Female"} label={"Female"}> {"Female"} </Select.Option>
                    <Select.Option key={"Male"} value={"Male"} label={"Male"}> {"Male"} </Select.Option>

                </Select>     
                       </Form.Item>
          </Col>:""
          }

          {type == 'Vendor Staff' ?
            <Col xs={24} xl={24}>
              <Form.Item
                label="Select Vendor"
                name="vendor_id"
                rules={[
                  { required: true, message: "Please select the vendor!" },
                ]}
              >
                <Select showSearch placeholder="Select Vendor" filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}>
                  {vendorList && vendorList.length > 0 ? vendorList.map((item) => (
                    <Select.Option key={item._id} value={item._id} label={item.name}> {item.name} </Select.Option>
                  )) : null}
                </Select>
              </Form.Item>
            </Col>
            : null}

          {type == 'Driver' ?
            <>
              <Col span={24} lg={8} sm={12}>
                <Form.Item className="mb-1"
                  label="Cash Limit"
                  name="cash_limit"
                  initialValue={0}
                  normalize={value => value.trimStart()}
                  rules={[
                    { pattern: /[0-9]/, message: "Only numbers are allowed" }
                  ]}
                >
                  <Input maxLength={20} autoComplete="off" placeholder="Enter Driver Cash Limit" />
                </Form.Item>
              </Col>

              <Col span={24} lg={8} sm={12}>
                <Form.Item
                  label="Select Type"
                  name="type"
                  rules={[
                    { required: true, message: "Please select the type!" },
                  ]}
                >
                  <Select showSearch placeholder="Select Type" filterOption={(input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase())}>
                    {DRIVER_TYPE ? DRIVER_TYPE.map((item) => (
                      <Select.Option key={item.value} value={item.value} label={item.name}> {item.name} </Select.Option>
                    )) : null}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24} lg={8} sm={12}>

                <Form.Item label={"Upload License (Width : 1130 x Height : 640)"} name="license_image">
                  <SingleImageUpload value={license} fileType={FileType} imageType={'user'} btnName={'License'} onChange={(data) => handleLicenseImage(data)} />
                </Form.Item>

                {license && license.length > 0 &&
                  <div className="mt-2">
                    <Image width={100} src={license && license.length > 0 && license !== "" ? license : notfound}></Image>
                  </div>
                }

              </Col>

              <Col span={24} lg={8} sm={12}>

                <Form.Item label={"Upload Document"} name="other_document">

                  <MultipleImageUpload data={otherDocument && otherDocument.length > 0 ? [...otherDocument.map((item) => item.url)] : []} fileType={FileType} imageType={'user'} btnName={'Documents'} onDelete={(data) => handleOtherDocumentDelete(data)} onChange={(data) => handleOtherDocument(data)} />

                </Form.Item>

              </Col>
            </>
            : null}

            {type == "Sub Admin" ? 
            <>
            
            </>: null}

          {type == 'Vendor' ?
            <>
              <Col xs={12} xl={12}>
                <Form.Item
                  label="Business Name"
                  name="business_name"
                  normalize={value => value.trimStart()}
                  rules={[
                    { required: true, message: "Please Enter the business name!" },
                    { max: 200, message: "Business name should not contain more then 200 characters!" },
                    { min: 2, message: "Business name should contain at least 2 characters!" },
                  ]}
                >
                  <Input autoComplete="off" placeholder="Enter Business Name" />
                </Form.Item>
              </Col>

              <Col xs={12} xl={12}>
                <Form.Item
                  label="Associated Sub Admin"
                  name="associated_sub_admin"
                  normalize={value => value.trimStart()}
                  rules={[
                    { required: true, message: "Please Select Associated Sub Admin!" },
                  ]}
                >
                  <Select placeholder="Select Sub-admin" onChange={(val) => console.log(val)}>
                    {subAdmins.map((ad) => {
                      return <Option key={ad?._id} className="" value={ad?._id}>{ad?.name}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24} lg={8} sm={12}>
                <Form.Item className="mb-1"
                  label="Images per service"
                  name="images_per_service"
                  rules={[
                    { required: true, message: "Please Enter the number of images per service!" },
                    { pattern: /[0-9]/, message: "Only numbers are allowed" }
                  ]}
                >
                  <InputNumber maxLength={20}  autoComplete="off" placeholder="Enter the number of images per service" />
                </Form.Item>
              </Col>

              {/* <Col xs={12} xl={12}>
                <Form.Item
                  label="Trade License Number"
                  name="trade_license_number"
                  normalize={value => value.trimStart()}
                  rules={[
                    { required: true, message: "Please Enter the trade license number!" },
                    { max: 50, message: "Commission should not contain more then 50 characters!" },
                    { min: 1, message: "Commission should contain at least 1 characters!" },
                  ]}
                >
                  <Input autoComplete="off" placeholder="Enter Trade License Number" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label={"Upload Trade License (Width : 1130 x Height : 640)"} name="trade_license_image"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value !== undefined && value?.length > 0) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Trade license image is required'));
                      },
                    }
                  ]}>
                  <SingleImageUpload value={tradeLicense} fileType={FileType} imageType={'user'} btnName={'Trade License'} onChange={(data) => handleTradeLicenseImage(data)} />
                </Form.Item>

                {tradeLicense && tradeLicense.length > 0 &&
                  <div className="mt-2">
                    <Image width={100} src={tradeLicense && tradeLicense.length > 0 && tradeLicense !== "" ? tradeLicense : notfound}></Image>
                  </div>
                }
              </Col> */}
            </>
            : null}

          {!['Sub Admin'].includes(type) ?
            <Col md={24}>
              <Form.Item
                label="Location" // (Drag Marker for Selecting Location)
                name="location"
                rules={[
                  { required: true, message: "Please select the location!" },
                ]}
              >
                <LocationMap onChange={handleLocationChange} userData={data}  
                editLocation={location}
                  type={type} />
              </Form.Item>
            </Col>
            : null}

        </Row>
      </Form>
    </Modal >
  )
};

export default UserFrom;