import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Switch,
  Form,
  Skeleton,
  Image,
  Select,
  Radio,
  InputNumber,
  Space,
  TimePicker,
} from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { useNavigate } from "react-router";
import apiPath from "../../constants/apiPath";
import SingleImageUpload from "../../components/SingleImageUpload";
import notfound from "../../assets/images/not_found.png";
import moment from "moment";
import LocationMap from "../User/LocationMap";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

function Edit() {
  const sectionName = "Venue";
  const routeName = "venue-manager";

  const api = {
    addEdit: apiPath.addEditVenue,
    category: apiPath.categoryList,
    subCategory: apiPath.subCategoryList,
    product: apiPath.productList,
    view: apiPath.viewVenue,
  };

  const [form] = Form.useForm();
  const { request } = useRequest();
  const params = useParams();
  const [vendors, setVendor] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];
  const [image, setImage] = useState([]);
  const [file, setFile] = useState();
  const [subAdmins, setSubadmins] = useState([]);
  const [logo, setLogo] = useState([])
  const [thumbNail, setThumbnail] = useState("")


  const [switchState, setSwitchState] = useState(false);
  const [location, setLocation] = useState();
  const [tagsCount, setTagsCount] = useState();

  const handleStartTimeChange = (time, timeString) => {
    // setStartTime(timeString);
  };

  const handleEndTimeChange = (time, timeString) => {
    // setEndTime(timeString);
  };

  const fetchData = (id) => {
    request({
      url: api.view + "/" + id,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setLoading(false);

        form.setFieldsValue({
          ...data,
          start_time: moment(new Date("2024-05-07T" + data.start_time)),
          end_time: moment(new Date("2024-05-07T" + data.end_time)),
          address: data.location,
          expense_indicator: data.expense_indicator,
          crowd_type: data.crowd_type[0],
          vendor_id: data?.vendor_id?._id,
          // is_featured: data.is_featured
        });
        setSwitchState(data.is_featured);

        setLocation({
          location: data?.location,
          latitude: data?.latitude,
          longitude: data?.longitude,
          // city: data?.city,
          // country: data?.country,
          // postal_code: data?.postal_code,
        });
        setImage(data.image)
        setLogo(data.logo);
        setFile([data.image]);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };
  const handleLogo = (data) => {
    console.log(data, "logo>>>>>>>>>>.")
    setLogo(data[0].url);
}
  const handleImage = (data) => {
    console.log(data, "data>>>>>>>>>>>")
    setImage(data[0].url);
    setThumbnail(data[0].thumbUrl);
  };

  const OnUpdate = (values) => {
    console.log(values.tags, "tags>>>>>>>>>");
    const payload = {
      ...values,
      is_featured: switchState,
      image: image,
      map_icon: thumbNail,
      logo: logo,
      venue_id: params.id,
      latitude: values.location.latitude,
      location: values.location.location,
      longitude:values.location.longitude
    };
    payload["start_time"] = moment(payload.start_time).format("HH:mm");
    payload["end_time"] = moment(payload.end_time).format("HH:mm");
    if (payload.start_time.split(":")[0].length < 2) {
      payload["start_time"] = 0 + payload.start_time;
    }
    if (payload.end_time.split(":")[0].length < 2) {
      payload["end_time"] = 0 + payload.end_time;
    }
    setLoading(true);
    request({
      url: api.addEdit + "/" + params.id,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          navigate(`/venue`);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleLocationChange = (value) => {
    setLocation(value);
  };

  useEffect(() => {
    setLoading(true);
    fetchData(params.id);
  }, []);

  const crowdType = [
    "Young Professionals",
    "College Students",
    "Tourists",
    "Social Butterflies",
    "Couples",
    "Regulars",
    "Older",
    "Fashionistas",
  ];
  const musicType = [
    "Hip Hop",
    "Jazz",
    "Pop",
    "Reggae",
    "Punk",
    "Classical",
    "Rock",
    "Electronic",
    "Latin",
  ];

  const addCoverChargeField = () => {
    setTagsCount(tagsCount + 1);
  };
  const getVendors = () => {
    request({
      url: apiPath.listingVendors,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
          console.log(data.data, 3222);
        }
        setVendor(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getSubadmins = () => {
    request({
      url: apiPath.subAdmins,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
          console.log(data.data, 3222)
        }
        setSubadmins(data.data.data);
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }


  useEffect(() => {
    getVendors();
    getSubadmins();
  }, []);
  const indicator = ["$", " $$", "$$$", "$$$$"];


  return (
    <>
      {loading ? (
        [1, 2, 3, 4].map((item) => <Skeleton active key={item} />)
      ) : (
        <Form
          className="edit-page-wrap colPadding"
          initialValues={{
            tags: [
              {
                position: 1,
                question: "",
                isMulti: "no",
                options: [{ option: "" }],
              },
            ],
          }}
          form={form}
          onFinish={OnUpdate}
          autoComplete="off"
          layout="verticle"
          name="subscription_form"
        >
          <Card title={"Update " + sectionName}>
            <Row gutter={[24, 0]}>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Enter subscription name!" },
                  ]}
                >
                  <Input autoComplete="off" placeholder="Enter Venue Name" />
                </Form.Item>
              </Col>

              <Col span={24} lg={12} sm={12}>
                <Form.Item
                  className="mb-1"
                  label="Vendor Name"
                  name="vendor_id"
                  normalize={(value) => value.trimStart()}
                  rules={[{ required: true, message: "Please select vendor!" }]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    showSearch // Enable search functionality
                    filterOption={(inputValue, option) =>
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    placeholder="Select Vendor"
                    onChange={(value) => form.setFieldValue("vendor_id", value)}
                    options={vendors.map((v) => ({
                      label: v?.name,
                      value: v?._id,
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Sub-Admin Name"
              name="subadmin_id"
              normalize={value => value.trimStart()}
             
            >
              <Select
                style={{
                  width: '100%',
                }}
                showSearch  // Enable search functionality
                filterOption={(inputValue, option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                placeholder="Select Sub-admins"
                onChange={(value) => form.setFieldValue("subadmin_id", value)}
                options={subAdmins.map(v => ({
                  label: v?.name,
                  value: v?._id
                }))}
              />
            </Form.Item>
          </Col>

              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Expense Indicator"
                  name="expense_indicator"
                  rules={[{ required: true, message: "Enter Icon or  Symbol" }]}
                >
  <Select
                                    style={{
                                        width: "100%",
                                    }}
                                    showSearch // Enable search functionality
                                    filterOption={(inputValue, option) =>
                                        option.label
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase())
                                    }
                                    placeholder="Select expense indicator"
                                    onChange={(value) =>
                                        form.setFieldValue("expense_indicator", value)
                                    }
                                    defaultValue={form.getFieldValue("expense_indicator")}
                                    options={indicator.map((v) => ({
                                        label: v,
                                        value: v,
                                    }))}
                                />{" "}                  </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  label="Upload Logo"
                  name="logo"
                >
                  <SingleImageUpload
                    value={logo}
                    fileType={FileType}
                    imageType={"venue"}
                    btnName={"Image"}
                    onChange={(data) => handleLogo(data)}
                  />

{logo && logo.length > 0 && 
                                    <div className="mt-2"> <Image width={120} src={logo !== "" ? logo : notfound}></Image> </div>
                                }

                </Form.Item>
              </Col>

              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  className="music-type"
                  name="music_type"
                  label="Music Types"
                  rules={[
                    { required: true, message: "Missing Music Type Selection" },
                  ]}
                >
                  {/* <Select
                                        style={{
                                        width: '100%',
                                        }}
                                        showSearch  // Enable search functionality
                                        filterOption={(inputValue, option) =>
                                        option.label.toLowerCase().includes(inputValue.toLowerCase())
                                        }
                                        placeholder="Select music"
                                        onChange={(value) => form.setFieldValue("music_type", value)}
                                        options={musicType.map(v => ({
                                        label: v,
                                        value: v
                                        }))}
                                    />  */}
                  <Input placeholder="Enter music type" />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  className="dress-code"
                  name="dress_code"
                  label="Dress Code"
                  rules={[{ required: true, message: "Missing Dress Code" }]}
                >
                  <Input placeholder="Enter dress code..." />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item className="Tags" name="tags" label="Tags">
                  <Select
                    mode="tags"
                    style={{
                      width: "100%",
                    }}
                    showSearch // Enable search functionality
                    placeholder="Select tags"
                    onChange={(value) => form.setFieldValue("tags", value)}
                  />
                  {/* <Input placeholder="Enter music type" /> */}
                </Form.Item>
              </Col>
              {/* <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                                <Button type="dashed" onClick={addCoverChargeField} block>
                                    <PlusOutlined /> Add Tags
                                </Button>
                            </Col> */}

              <Col span={24} sm={12}>
                <Form.Item label="Featured" name="is_featured">
                  <Switch
                    checked={switchState}
                    onChange={(checked) => setSwitchState(checked)}
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  label="Start Time"
                  name="start_time"
                  rules={[
                    { required: true, message: "Please select a start time" },
                  ]}
                >
                  <TimePicker
                    style={{
                      width: "100%",
                      height: "42px",
                      borderRadius: "6px",
                    }}
                   format="hh:mm a"
                    onChange={handleStartTimeChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  label="End Time"
                  name="end_time"
                  rules={[
                    { required: true, message: "Please select an end time" },
                  ]}
                >
                  <TimePicker
                    style={{
                      width: "100%",
                      height: "42px",
                      borderRadius: "6px",
                    }}
                    format="hh:mm a"
                    onChange={handleEndTimeChange}
                  />
                </Form.Item>
              </Col>

              <Col pan={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  // normalize={(value) => value.trimStart()}
                  label="Crowd Type"
                  name="crowd_type"
                  rules={[
                    {
                      required: true,
                      message: "Missing Crowd Type !",
                    },
                  ]}
                >
                  {/* <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        showSearch  // Enable search functionality
                                        filterOption={(inputValue, option) =>
                                            option.label.toLowerCase().includes(inputValue.toLowerCase())
                                        }
                                        placeholder="Select Crowd"
                                        onChange={(value) => form.setFieldValue("crowd_type", value)}
                                        options={crowdType.map(v => ({
                                            label: v,
                                            value: v
                                        }))}
                                    />                            */}

                  <Input placeholder="Enter crowd type" />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Cover charges"
                  name="cover_charges"
                  rules={[{ required: true, message: "Missing Charges!" }]}
                >
                  <Input
                    maxLength={180}
                    autoComplete="off"
                    placeholder="Enter charges"
                  />
                </Form.Item>
              </Col>

              <Col span={24} sm={24} md={24} lg={24} xl={24} xxl={18}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Bio (100 Characters)"
                  name="bio"
                  rules={[{ required: true, message: "Missing Description!" }]}
                >
                  <Input
                    maxLength={180}
                    autoComplete="off"
                    placeholder="Enter Description"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={24} md={24} lg={24} xl={24} xxl={18}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label="Highlight/Detailed Introduction (100 Characters)"
                  name="detailed_description"
                  rules={[{ required: true, message: "Missing Description!" }]}
                >
                  <Input
                    maxLength={180}
                    autoComplete="off"
                    placeholder="Enter Description"
                  />
                </Form.Item>
              </Col>

              <Col span={24} sm={12} md={8} lg={12} xl={8} xxl={6}>
                <Form.Item
                  /* rules={[
                                        {
                                            validator: (_, value) => {
                                                if (value !== undefined && value?.length > 0) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Missing Image!'));
                                            },
                                        }
                                    ]} */
                  label="Upload Image"
                  name="image"
                >
                  <SingleImageUpload
                    value={image}
                    fileType={FileType}
                    imageType={"venue"}
                    btnName={"Image"}
                    onChange={(data) => handleImage(data)}
                  />

                  {file && file.length > 0 && (
                    <div className="mt-2">
                      {" "}
                      <Image
                        width={120}
                        src={image !== "" ? image : notfound}
                      ></Image>{" "}
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col md={24}>
                <Form.Item
                  label="Location" // (Drag Marker for Selecting Location)
                  name="location"
                  rules={[
                    { required: true, message: "Please select the location!" },
                  ]}
                >
                  <LocationMap
                    onChange={handleLocationChange}
                    editLocation={location}
                    // userData={data}
                    //   type={type}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Form.Item className="btn-row float-right pb-2 mt-3">
            <Link className="ant-btn ant-btn-primary" to={`/venue`}>
              Back
            </Link>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
}

export default Edit;
