import { BrowserRouter, Routes, Route, Outlet, Navigate} from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { AppContextProvider } from './context/AppContext';
import React, { useContext, Suspense } from 'react';
import Home from "./pages/Auth/Home";
import Profile from "./pages/Auth/Profile";
import SignIn from "./pages/Auth/SignIn";
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import Loader from './components/Loader';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Error from './pages/Error';
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";

import Guestlist from './pages/GuestList/Index';
import GuestlistView from "./pages/GuestList/View";

import Customer from './pages/User/Customer/Index';
import CustomerView from './pages/User/Customer/View';
import Activity from './pages/User/Activity';
import ScannerStaff from './pages/ScannerStaff/Index';
import ScannerStaffView from './pages/ScannerStaff/View';
import Group from './pages/Group/index';
import GroupView from './pages/Group/view';
import SubAdmin from './pages/SubAdmin/Index';
import SubAdminView from './pages/SubAdmin/View';
import SubAdminPermission from './pages/SubAdmin/Permission';

import Vendor from './pages/User/Vendor/Index';
import VendorView from './pages/User/Vendor/View';
import PhotosManager from './pages/Photos/Index';
import PhotosManagerView from './pages/Photos/View';

import VendorStaff from './pages/User/VendorStaff/Index';
import VendorStaffView from './pages/User/VendorStaff/View';
import TableManager from './pages/Table/Index';
import TableManagerView from './pages/Table/View';
import TicketBuySummary from './pages/TicketSummary/Index';

import Content from './pages/Content/Index';
import Discount from './pages/Discount/Index';

import ContentView from './pages/Content/View';
import ContentEdit from './pages/Content/Edit';

import Transaction from './pages/Transaction/Index';
import TransactionView from './pages/Transaction/View';

import Setting from './pages/Setting/Edit';

import Venue from './pages/Venue/Index';
import AddVenue from './pages/Venue/AddForm';

import Event from './pages/Event/Index';
import Booking from './pages/Booking/Index';
// import Blog from './pages/Setting/Edit';
import News from './pages/News/Index';

import Notification from './pages/Notification/Index';
import ActivityNotification from './pages/Notification/Index';

import EmailTemplate from './pages/EmailTemplate/Index';
import EmailTemplateView from './pages/EmailTemplate/View';
import EmailTemplateEdit from './pages/EmailTemplate/Edit';
import UserForm from './pages/Event/AddForm';
import Terms from './pages/Terms';
import Edit from './pages/Venue/EditForm';
import EventView from './pages/Event/View';
import Listing from './pages/Notification/Listing';

window.Buffer = window.Buffer || require("buffer").Buffer;

function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<Loader />}>
            <BrowserRouter>
              <ScrollToTop />
              <ToastContainer closeOnClick={false} />
              <AppRoutes />
            </BrowserRouter>
          </Suspense>
        </I18nextProvider>
      </AppContextProvider>
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Routes>
      <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <SignIn />} />
      <Route exact path="/content/:type" element={<Terms />} />
      {/* <Route path="/" element={<SignIn />} /> */}

      <Route
        path="/"
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        {/* Auth Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/dashboard" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />

        {/* Vendor Routes */}
        <Route exact path="/user/vendor" element={<Vendor />} />
        <Route exact path="/user/vendor/view/:id" element={<VendorView />} />

        {/* Customer Routes */}
        <Route exact path="/user/customer" element={<Customer />} />
        <Route exact path="/user/customer/view/:id" element={<CustomerView />} />
        <Route exact path="/user/activity/:id" element={<Activity />} />

        {/* Vendor Staff Routes */}
        <Route exact path="/user/vendor-staff" element={<VendorStaff />} />
        <Route exact path="/user/vendor-staff/view/:id" element={<VendorStaffView />} />

        {/* Transaction Routes */}
        <Route exact path="/transaction" element={<Transaction />} />
        <Route exact path="/transaction/view/:id" element={<TransactionView />} />
        <Route exact path="/guest-list" element={<Guestlist />} />
        <Route exact path="/guest-list/view/:id" element={<GuestlistView />} />
        <Route exact path="/payment/tickets" element={<TicketBuySummary />} />

        <Route exact path="/photos" element={<PhotosManager />} />
        <Route exact path="/photos/view/:id" element={<PhotosManagerView />} />

        {/* Scanner Staff Manager Routes */}
        <Route exact path="/scanner-staff" element={<ScannerStaff />} />
        <Route exact path="/scanner-staff/view/:id" element={<ScannerStaffView />} />

        {/* Venue Routes */}
        <Route exact path="/venue" element={<Venue />} />
        <Route exact path="/add-venue" element={<AddVenue />} />
        <Route exact path="/edit-venue/:id" element={<Edit />} />
        <Route exact path="/discount" element={<Discount />} />
        <Route exact path="/table" element={<TableManager />} />
        <Route exact path="/table/view/:id" element={<TableManagerView />} />

        <Route exact path="/news" element={<News />} />
        {/* Event Routes */}
        <Route exact path="/event" element={<Event />} />
        <Route exact path="/event/add" element={<UserForm />} />
        <Route exact path="/event/edit/:id" element={<UserForm />} />
        <Route exact path="/event/view/:id" element={<EventView/> }/>

        <Route exact path="/setting" element={<Setting />} />

        {/* Booking Routes */}
        <Route exact path="/booking" element={<Booking />} />

        {/* Blog Routes */}
        <Route exact path="/blogs" element={<Setting />} />

        {/* Notification Routes */}
        <Route exact path="/listing" element={<Listing />} />

        <Route exact path="/notification" element={<Notification />} />
        <Route exact path="/groups" element={<Group />} />
        <Route exact path="/groups/view/:id" element={<GroupView/>}/>
        {/* Activity Notification Routes */}
        <Route exact path="/activity-notification" element={<ActivityNotification />} />

        {/* Sub Admin Routes */}
        <Route exact path="/sub-admin" element={<SubAdmin />} />
        <Route exact path="/sub-admin/view/:id" element={<SubAdminView />} />
        <Route exact path="/sub-admin/permission/:id" element={<SubAdminPermission />} />

        {/* Content Routes */}
        <Route exact path="/content" element={<Content />} />
        <Route exact path="/content/update/:slug?" element={<ContentEdit />} />
        <Route exact path="/content/view/:slug" element={<ContentView />} />

        {/* Email Template Routes */}
        <Route exact path="/email-template" element={<EmailTemplate />} />
        <Route exact path="/email-template/update/:id?" element={<EmailTemplateEdit />} />
        <Route exact path="/email-template/view/:id" element={<EmailTemplateView />} />
      </Route>

      <Route path="*" element={<Error />} />
    </Routes>
  );
}

const Layout = () => {
  return (
    <>
      <Outlet />
    </>
  );
}

export default App;
