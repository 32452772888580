import { Row, Col, Modal, Form, Input, Button, Checkbox } from "antd";
import 'react-phone-input-2/lib/style.css';
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/lang";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import moment from "moment";

const EventForm = ({ type, path, sectionName, show, hide, data, refresh }) => {

  const api = {
    userList: apiPath.allUserList,
  }

  const [form] = Form.useForm();
  const { request } = useRequest();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < events.length);
    setCheckAll(list.length === events.length);
  };
  
  useEffect(() => {
    getEvents();
    console.log(data, 33)
    if (data) {
      form.setFieldsValue({ ...data });
      const eventIds = data.events.map(event => event._id); // Extract event IDs
      setCheckedList(eventIds); 
    }
  }, [data]);

  const onCreate = (values) => {
    setLoading(true);
    console.log(values, 411)
    const payload = {
      ...values,
      events: checkedList // Include the checked list in the payload
    };

    request({
      url: `${data ? path + "/" + data.id : path}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error.message, Severty.ERROR);
      },
    });
  };

  const getEvents = () => {
    request({
      url: apiPath.eventByVenueId + "/id",
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          setEvents(data.data.data); // Format the data for Checkbox.Group
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err.message, Severty.ERROR);
      }
    });
  };

  return (
    <Modal
      width={1100}
      open={show}
      title={`${data ? lang("Update") + " " + sectionName : lang("Add") + " " + lang("New") + " " + sectionName}`}
      okText={`${data ? lang("Update") : lang("Add")}`}
      cancelText={
        <>
          <CloseOutlined style={{ marginRight: '8px' }} />
          {lang("Cancel")}
        </>
      }
      onCancel={hide}
      footer={[
        <Button key="back" onClick={hide}>
          <CloseOutlined style={{ marginRight: '8px' }} />
          {lang("Cancel")}
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
          <CheckOutlined style={{ marginRight: '8px' }} />
          {data ? lang("Update") : lang("Add")}
        </Button>,
      ]}
    >
      <Form id="create" form={form} initialValues={{ deal_in: "Products" }} onFinish={onCreate} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={24} lg={24} sm={24}>
            <div className="modal-event-leanth">
            {console.log(events.length, "event length>>>>>>>>>>>>>>")}

              {events.map(event => (
                <div key={event._id} style={{ marginBottom: '8px' }}>
                    {console.log(event.name, "event name>>>>>>>")}
                    {
                       event.recurringDate || event.recurringDay ?
                       <Checkbox
                    value={event._id}
                    checked={checkedList.includes(event._id)}
                    onChange={(e) => {
                      const newCheckedList = e.target.checked
                        ? [...checkedList, event._id]
                        : checkedList.filter(value => value !== event._id);
                      onChange(newCheckedList);
                    }}
                  >
                    <h3> 
                    {event.name}
                        </h3>
                        <p> 
                             {event.recurringDate? "Event Date:  "+moment(event.recurringDate).format("DD-MM-YYYY"): "Event Day:  "+event.recurringDay}
                            </p><p>
                            Event Time: {event.start_time&& event.end_time? moment(event.start_time).format("hh:mm")+ "-" + moment(event.end_time).format("hh:mm"):"-"}
                        </p>
                  </Checkbox>
                  :""
                    }
                  
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EventForm;
