export const s3Config = () => ({
    region: 'us-east-1',
    credentials: {
      accessKeyId: "AQ416YMO1HMJY20Z65AK",
      secretAccessKey: "iqiaFEVwQ4cfbtqy8xCjEBCIHfdAnU0BXAOHOTxO",
    },
    endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080",
    forcePathStyle: true,
});
// S3uRL   =   https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/
// Access key: AQ416YMO1HMJY20Z65AK
// Secret Key: iqiaFEVwQ4cfbtqy8xCjEBCIHfdAnU0BXAOHOTxO
// Bucket name : 1544-bucket-2