import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Table,
    Tooltip,
  } from "antd";
  import moment from "moment";
  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router";
  import useNotification from "../../hooks/useNotification";

  import ShowTotal from "../../components/ShowTotal";
  import apiPath from "../../constants/apiPath";
  import { Severty, ShowToast } from "../../helper/toast";
  import useRequest from "../../hooks/useRequest";
  import ConfirmationDelete from "../../components/ConfirmationDelete"
  
  function Listing() {
    const { confirmDelete } = ConfirmationDelete()
    const userProfile = JSON.parse(localStorage.getItem("userProfile"))
    console.log(userProfile.user_type, 255)
    const permission= userProfile.user_type== "SubAdmin"?userProfile.permission.EventSection:undefined;
  
    const sectionName = "Notification";
    const routeName = "notification";
  
    const api = {
      add: apiPath.addNotification,
      list: apiPath.activityNotificationList,
      customerList: apiPath.customerList,
      serviceProviderList: apiPath.serviceProviderList,
    };
    const {recentActivityNotifications, fetchRecentActivityNotifications, deleteNotification} = useNotification();

    const { request } = useRequest();
    const [list, setList] = useState(recentActivityNotifications);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [visible, setVisible] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const navigate = useNavigate();
  
    const view = (id) => {
      navigate(`/${routeName}/view/${id}`);
    };
  
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (_, { title }) => {
          return title ? title : "-";
        },
      },
    //   {
    //     title: "Notification For",
    //     dataIndex: "notification_for",
    //     key: "notification_for",
    //     render: (_, { user_type }) => {
    //       return user_type ? user_type : "-";
    //     },
    //   },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
        render: (_, { description }) => {
          return description ? description : "-";
        },
      },
      {
        title: "Users",
        dataIndex: "from_id",
        key: "from_id",
        render: (_, { from_id }) => {
          return from_id? from_id.name: "--";
        },
      },
      {
        title: "Created On",
        key: "created_at",
        dataIndex: "created_at",
        render: (_, { created_at }) => {
          return moment(created_at).format("MM-DD-YYYY");
        },
        ...(userProfile.user_type=="Admin"  ? {
        title: "Action",
        fixed: "right",
        render: (_, record) => {
          return (
            <>
           
            <Tooltip
              title={"Delete " + sectionName}
              color={"purple"}
              key={"delete" + routeName}
            >
              <Button title="Delete" onClick={() => confirmDelete({ record: record._id, path: apiPath.deleteNotification, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev) })}>
                <i className="fa fa-light fa-trash"></i>
              </Button>
            </Tooltip>
            </>
          );
        },
      }:[])
    }
    ];
  
    // const fetchData = (pagination, filters) => {
    //   setLoading(true);
    //   const filterActive = filters ? filters.is_active : null;
    //   request({
    //     url:
    //     apiPath.getNotification,
    //     method: "GET",
  
    //     onSuccess: (data) => {
    //       setLoading(false);
    //       setList(data.data);
    //       // setPagination((prev) => ({
    //       //   current: pagination.current,
    //       //   total: data.data.list.totalDocs,
    //       // }));
    //     },
  
    //     onError: (error) => {
    //       ShowToast(error, Severty.ERROR);
    //     },
    //   });
    // };
  
    // const handleChange = (pagination, filters) => {
    //   fetchData(pagination, filters);
    // };
  
    useEffect(() => {
        //   console.log(recentActivityNotifications)
        //   fetchData(pagination);
        fetchRecentActivityNotifications();
        setLoading(false);
    }, [refresh]);
  
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title={sectionName + " Management"}
                // extra={
                //   <Button onClick={(e) => setVisible(true)}>
                //     Send New {sectionName}
                //   </Button>
                // }
              >
                <div className="total-record-cls">
                  <h4 className="text-right">
                    Total {recentActivityNotifications.length} Notifications
                  </h4>
                </div>
                <div className="table-responsive customPagination">
                  <Table
                    loading={loading}
                    columns={columns}
                    dataSource={recentActivityNotifications}
                    pagination={{ 
                      ...pagination,
                      defaultPageSize: 10,
                       responsive: true, 
                      //  total: pagination.total, 
                       showSizeChanger: true, 
                       pageSizeOptions: ['10', '20', '30', '50'] 
                      }}
                    // onChange={handleChange}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
  
        {visible && (
          <SendNotiFrom
            show={visible}
            api={apiPath.sendNotification}
            data={list}
            hide={() => setVisible(false)}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}
      </>
    );
  }
  
  const SendNotiFrom = ({ show, api, hide, refresh }) => {
    const [form] = Form.useForm();
    const [selectType, setSelectType] = useState("All");
    const [userList, setUserList] = useState([]);
    const [selectUser, setSelectUser] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const { request } = useRequest();
    const [loading, setLoading] = useState(false);
  
    const handleSelectAll = (e) => {
      const allUserIds = userList.map((user) => user._id);
      setSelectUser(e.target.checked ? allUserIds : []);
      setSelectAll(e.target.checked);
    };
  
    const handleTypeChange = (value) => {
      setSelectType(value);
      getList(value);
      setSelectUser([]);
      setLoading(false);
      form.resetFields(["user"]);
    };
  
    const handleUserChange = (value) => {
      setSelectUser(value);
      setSelectAll(value.length === userList.length);
    };
  
    const getList = (type) => {
      setLoading(true);
      request({
        url:
          type == "Customer"
            ? `${apiPath.userList}`
            : `${apiPath.vendorList}`,
        method: "GET",
        onSuccess: ({ data }) => {
          console.log(data, 2300)
          setUserList(data.data);
        },
        onError: (error) => {
          ShowToast(error, Severty.ERROR);
        },
      });
    };
  
    const filterUserOption = (input, option) => {
      console.log(input, option, "------ hfdkjgfhdbfjdg");
      const inputValue = input.toLowerCase();
  
      let optionLabel = "";
      if (option && option.props && option.props.children) {
        if (Array.isArray(option.props.children)) {
          // If option.props.children is an array, join its elements
          optionLabel = option.props.children.join("").toLowerCase();
        } else if (typeof option.props.children === "string") {
          // If option.props.children is a string, use it directly
          optionLabel = option.props.children.toLowerCase();
        } else {
          // Handle other cases if necessary
          optionLabel = option.props.children.toString().toLowerCase();
        }
      }
  
      if (!optionLabel) {
        // Handle the case where optionLabel is still undefined or empty
        return false;
      }
  
      const [code, phone] = optionLabel.split("-").map((item) => item || ""); // Handle the case where code or phone might be undefined
  
      return (
        optionLabel.includes(inputValue) ||
        (code && code.includes(inputValue)) ||
        (phone && phone.includes(inputValue))
      );
    };
  
    const onCreate = (values) => {
      const { title, message } = values;
      setLoading(true);
      if(selectAll && selectType == "Customer"){
        setSelectUser(userList)
      }else if(selectAll && selectType == "Vendor"){
        setSelectUser(userList)
  
      }
      const payload = {};
      payload.title = title;
      payload.message = message;
      payload.user_type = selectType ? selectType : "All";
      payload.is_all = selectAll;
      if (selectType != "All") {
        payload.selectUser = selectUser.length > 0 ? selectUser : [];
      }
      request({
        url: apiPath.sendNotification,
        method: "POST",
        data: payload,
        onSuccess: (data) => {
          setLoading(false);
          if (data.status) {
            ShowToast(data.message, Severty.SUCCESS);
            hide();
            refresh();
          } else {
            ShowToast(data.message, Severty.ERROR);
          }
        },
        onError: (error) => {
          ShowToast(error.response.data.message, Severty.ERROR);
          setLoading(false);
        },
      });
    };
  
    return (
      <Modal
        open={show}
        width={700}
        title="Send Notification"
        okText="Send"
        onCancel={hide}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
        }}
      >
        <Form id="create" form={form} onFinish={onCreate} layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item
                label="Select User Type"
                name="name"
                rules={[{ required: true, message: "Please Enter the name!" }]}
              >
                <Select
                  placeholder="Select User Type"
                  onChange={handleTypeChange}
                >
                  <option key="1" value="All">
                    All
                  </option>
                  <option key="2" value="Customer">
                    Customer
                  </option>
                  <option key="3" value="Vendor">
                    Vendor
                  </option>
                </Select>
              </Form.Item>
            </Col>
  
            {/* <Col span={24}> */}
            {selectType != "All" ? (
              <>
                <Col span={24} md={24} className="verticalCustomSelect">
                  {userList.length != 0 && (
                    <>
                      <Checkbox checked={selectAll} onChange={handleSelectAll}>
                        Select All
                      </Checkbox>{" "}
                      {/* <div className="mt-2"> </div> */}
                    </>
                  )}
                </Col>
  
                <Col span={24} md={24}>
                  {!selectAll ? (
                    <Form.Item
                      label={`Select ${selectType}`}
                      name="user_type"
                      rules={[
                        { required: true, message: "Please select users!" },
                      ]}
                    >
                      <Select
                        className="selectNotification selectbox-width newSelect multiSelect"
                        showSearch
                        onChange={handleUserChange}
                        filterOption={filterUserOption}
                        placeholder={`Select ${selectType}`}
                        mode="multiple"
                        value={selectUser}
                      >
                        {userList &&
                          userList.length > 0 &&
                          userList.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </Select>
                    </Form.Item>
                  ) : null}
                </Col>
              </>
            ) : null}
            {/* </Col> */}
  
            <Col span={24}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please enter the title!" }]}
              >
                <Input placeholder="Enter Notification Title" maxLength={150} />
              </Form.Item>
            </Col>
  
            <Col span={24}>
              <Form.Item
                label="Message"
                name="message"
                rules={[{ required: true, message: "Please enter the message!" }]}
              >
                <Input.TextArea
                  showCount
                  maxLength={1000}
                  style={{ height: 120, marginBottom: 15 }}
                  placeholder="Enter Notification Message"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };
  
  export default Listing;
  