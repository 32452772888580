import { Row, Col, Radio, Modal, Image, Button, Form, Input, Select,Checkbox, InputNumber, Calendar } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React, { useState, useEffect, useRef } from "react";
import useRequest from "../../hooks/useRequest";
// import LocationMap from "./LocationMap";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { DatePicker } from 'antd';
import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import lang from "../../helper/lang";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Option } from "antd/lib/mentions";
import moment from "moment";

const AddForm = ({ type, path, sectionName, show, hide, data, refresh }) => {

  const api = {
    userList: apiPath.allUserList,
  }

  const FileType = ["image/png", "image/jpg", "image/jpeg", "image/avif", "image/webp", "image/gif"];
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [venues, setVenues] = useState([]);
  const [autoDelete, setAutoDelete] = useState(false);
  const [highlights, setHighlights] = useState(false);
  const [autoDeactivate, setAutoDeactivate] = useState(false);
  const [deactivedate, setDeactiveDate] = useState("");
  const [deleteDate, setDeleteDate] = useState("");

  const onCheckboxChange = (values) => {
    console.log(values, 33333333)
    if(values.autoDeactivate?.includes(true)){
      // setAutoDelete(false);

      setAutoDeactivate(true);
    }else if(values.autoDelete?.includes(true)){
      // setAutoDeactivate(false);
      setAutoDelete(true);

    }
    // setAutoDeactivate(false);
    // setAutoDelete(false)
    };
  const getVenues = () => {
    request({
      url: apiPath.listVenue + `?status=true`,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) {
          setVenues(data.data);
        }
      },
      onError: (err) => {
        console.log(err);
      }
    })
  }

  const handleVenueImage = (data) => {
    setImage(data[0]?.url);
  }

  useEffect(() => {
    getVenues();
    if (!data) return;
    form.setFieldsValue({ ...data,
     

     });
     setDeactiveDate(moment(data.deactivateDate));
     setDeleteDate(moment(data.deleteDate));
     setAutoDeactivate(data.autoDeactivate);
     setAutoDelete(data.autoDelete)
    setImage(data.image);
  }, [data])

  const onCreate = (values) => {
    // setLoading(true);

    const payload = {
      ...values,
      image: image?.length ? image : '',
      deactivateDate: deactivedate,
      deleteDate: deleteDate,
      highlights: highlights
    };
    console.log(payload);
    request({
      url: `${data ? path + "?id=" + data._id : path}`,
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    })
  };
//   const onFormValuesChange = (changedValues, allValues) => {
//     setFormData(allValues);
//   };

// const onRadioChange = (e) => {
//   console.log(e.target.value, 99999)

//     setAutoDelete(e.target.value);
// }

const onDeacChange = (date, dateString) => {
  setDeactiveDate(dateString);
}

  function onChange(date, dateString) {
    console.log( dateString);
    setDeleteDate(dateString);
  }

  return (
    <Modal
      width={800}
      open={show}
      title={`${data ? lang("Update") + " " + sectionName : lang("Add") + " " + lang("New") + " " + sectionName}`}
      okText={`${data ? lang("Update") : lang("Add")}`}
      cancelText={
        <>
          <CloseOutlined style={{ marginRight: '8px' }} />
          {lang("Cancel")}
        </>
      }
      onCancel={hide}
      okButtonProps={{
        form: 'create',
        htmlType: 'submit',
        loading: loading,
        icon: < CheckOutlined />,
      }}
    >
      <Form id="create" form={form} initialValues={{}} onFinish={onCreate} layout="vertical">
        <Row gutter={[24, 16]}>
          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="News Title"
              name="title"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please Enter the title!" },
                { max: 200, message: "Title should not contain more then 200 characters!" },
                { min: 2, message: "Title should contain at least 2 characters!" },
              ]}
            >
              <Input autoComplete="off" placeholder="Enter News Title" />
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="News Description"
              name="description"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select venue!" },
              ]}
            >
                            <Input autoComplete="off" placeholder="Enter News Description" />

             
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Auto Delete"
              name="autoDelete"
             
            >
    <Radio.Group onChange={(e) => setAutoDelete(e.target.value)}>
      <Radio value={true}>Yes</Radio>
      <Radio value={false}>No</Radio>
    </Radio.Group>
                   </Form.Item>
          </Col>
          {autoDelete && (

          <Col span={24} sm={12}>
            <Form.Item
              label="Deleting Date"
              name="deleteDate"
             
            >
              
              <DatePicker onChange={onChange} format="YYYY-MM-DD" defaultValue={deleteDate} />            </Form.Item>
          </Col>
          )}
          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Auto Deactivate"
              name="autoDeactivate"
            >
   
   <Radio.Group onChange={(e) => setAutoDeactivate(e.target.value)}>
      <Radio value={true}>Yes</Radio>
      <Radio value={false}>No</Radio>
    </Radio.Group>
                   </Form.Item>
          </Col>
          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="News Type"
              name="type"
              normalize={value => value.trimStart()}
              rules={[
                { required: true, message: "Please select type!" },
              ]}
            >
                <Select
                style={{
                  width: '100%',
                }}
                showSearch  // Enable search functionality
                filterOption={(inputValue, option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                }
                placeholder="Select type"
                onChange={(value) => form.setFieldValue("type", value)}
                options={[
                  { value: 'news', label: 'News' },
                  { value: 'updates', label: 'Updates' },
                ]}
              />
             
            </Form.Item>
          </Col>

          <Col span={24} lg={12} sm={12}>
            <Form.Item className="mb-1"
              label="Highlights"
              name="highlights"
              rules={[
                { required: true, message: "Please select highlights!" },
              ]}
            >
               <Radio.Group onChange={(e) => setHighlights(e.target.value)}>
      <Radio value={true}>Yes</Radio>
      <Radio value={false}>No</Radio>
    </Radio.Group>
             
            </Form.Item>
          </Col>
         {autoDeactivate &&  <Col span={24} sm={12}>
            <Form.Item
              label="Deactivate Date"
              // name="deactivateDate"
             
            >
              
              <DatePicker onChange={onDeacChange} format="YYYY-MM-DD" defaultValue={deactivedate} />     
                     </Form.Item>
          </Col>}
          <Col span={12}>
            <Form.Item label={"Upload News Image (Width : 1130 x Height : 640)"} name="image"
              rules={[
                // {
                //   validator: (_, value) => {
                //     if (value !== undefined && value?.length > 0) {
                //       return Promise.resolve();
                //     }
                //     return Promise.reject(new Error('Venue Image is required'));
                //   },
                // }
              ]}>
              <SingleImageUpload value={image} fileType={FileType} imageType={'venue'} btnName={'News Image'} onChange={(data) => handleVenueImage(data)} />
            </Form.Item>

            {image && image.length > 0 &&
              <div className="mt-2">
                <Image width={100} src={image && image.length > 0 && image !== "" ? image : notfound}></Image>
              </div>
            }
          </Col>
        </Row>
      </Form>
    </Modal >
  )
};

export default AddForm;