import React, { useState, useEffect } from "react";
import { Card, Col, Row, Typography, Radio,Image,Tag, Skeleton,Avatar, Table, DatePicker, Badge } from "antd";
import { Link } from 'react-router-dom';
import { PRICE } from "../../constants/conts";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import { ShowToast, Severty } from "../../helper/toast";
import moment from 'moment';
import apiPath from "../../constants/apiPath";
import ConfirmationBox from "../../components/ConfirmationBox";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from "chart.js";
import { Bar } from "react-chartjs-2";
import lang from "../../helper/lang";
const { RangePicker } = DatePicker;

function Home() {

  const { Title, Text } = Typography;
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const api = {
    status: apiPath.statusCustomer
  }

  const [loading, setLoading] = useState(false)
  const [recentTransaction, setRecentTransaction] = useState([]);
  const [recentUser, setRecentUser] = useState([]);
  const [earning, setEarning] = useState([]);
  const [refresh, setRefresh] = useState(false)
  const [revenueGraphData, setGraphRevenueData] = useState({});
  const [barData, setBarData] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const navigate = useNavigate();

  ChartJS.register(CategoryScale, LinearScale, BarElement);

  const [dashboard, setDashboard] = useState({
    customerCount: 0,
    vendorCount: 0,
    venueCount: 0,
    eventCount: 0,
    last10Transaction: [],
    last10Guests: [],
  })

  const view = (id) => {
    navigate(`/user/customer/view/${id}`)
  }

  const options = {
    indexAxis: 'y',
  }

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"))
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"))
    } else {
      setStartDate()
      setEndDate()
    }
  };

  const count = [
    {
      today: `Total Active Customers`,
      title: `${dashboard && dashboard.customerCount ? dashboard.customerCount : 0}`,
      persent: "10%",
      icon: <i className="fas fa-users"></i>,
      bnb: "bnb2",
      url: '/user/customer'
    },
    {
      today: `Active Venues`,
      title: `${dashboard && dashboard.venueCount ? dashboard.venueCount : 0}`,
      icon: <i className="fas fa-people-carry"></i>,
      bnb: "bnb2",
      url: '/venue'
    },
    {
      today: `Active Events`,
      title: `${dashboard && dashboard.eventCount ? dashboard.eventCount : 0}`,
      icon: <i className="fas fa-user-tie"></i>,
      bnb: "bnb2",
      url: '/event'
    }
  ];

  const columns = [
    {
      title: `${lang("Name")}`,
      dataIndex: "name",
      key: "name",
      render: (_, { name, _id, image }) => {
        return (
          <>
            {!image ? (
              <>
                <div className="main-table-div8888">
                <Avatar className="cap" size={40}>{name ? name.charAt(0) : 'Not Available'}</Avatar>
                <a className="cap avatar-text" onClick={(e) => view(_id)}>{name ? name : 'Not Available'}</a>

                </div>
              </>
            ) : (
              <>
                <Image className="image-index-radius" src={image} />
                <a className="cap avatar-text" onClick={(e) => view(_id)}>{name ? name : 'Not Available'}</a>
              </>
            )}
          </>
        );
      },
    
    },
    {
      title: `${lang("Mobile")} ${lang("Number")}`,
      render: (_, { mobile_number, country_code }) => {
        return (
          (country_code ? "+" + country_code + "-" : "+1") + (mobile_number ? mobile_number : '')
        );
      },
     
    },
    {
      title: `${lang("Email")} ${lang("Address")}`,
      dataIndex: "email",
      key: "email",
      width: 210,
      render: (_, { email }) => {
        return email ? email : '-';
      },
     
    },
    // {
    //   title: `${lang("Gender")}`,
    //   key: "Gender",
    //   filters: [
    //     {
    //       text: 'Male',
    //       value: true,
    //     },
    //     {
    //       text: 'female',
    //       value: false,
    //     },
    //   ],
    //   render: (_, { gender }) => {
    //     return (
    //       <span >{ gender || '-'}</span>
    //     );
    //   },
    // },
    // {
    //   title: `${lang("Events")} ${lang("Joined")}`,
    //   dataIndex: "events",
    //   key: "events",
    //   width: 210,
    //   render: (_, { events_joined }) => {
    //     return events_joined ? events_joined : '-';
    //   },
    //   sorter: (a, b) => {
    //     let nameA = a.events_joined;
    //     let nameB = b.events_joined;
    //     if (nameA < nameB)
    //       return -1;
    //     if (nameA > nameB)
    //       return 1;
    //     return 0;
    //   },
    // },
    {
      title: `${lang("Status")}`,
      key: "is_active",
     
      render: (_, { is_active, _id }) => {
        let color = is_active ? 'green' : 'red';
        const type = "is_active";
        return (<a><Tag onClick={(e) => showConfirm({ record: _id, path: api.status, onLoading: () => setLoading(true), onSuccess: () => setRefresh(prev => !prev), type })} color={color} key={is_active}>{is_active ? "Active" : "Inactive"}</Tag></a>);
      },
    },
    {
      title: `${lang("Registered_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
      
    }
  ];

  const transactionColumn = [
    {
      title: `${lang("Transaction")} ${lang("ID")}`,
      render: (_, { transaction_id, _id }) => {
        return transaction_id ? (
          <p
          >
            {transaction_id ? transaction_id : "-"}
          </p>
        ) : (
          "-"
        );
      },
    },
    {
      title: `${lang("Vendor")} ${lang("Detail")}`,
      dataIndex: "vendors",
      key: "vendors",
      width: 280, // Width 200px
      render: ( vendors ) => {
        const { name, email, country_code, mobile_number } = vendors || {};
        const countryCode = country_code ? `+${country_code}-` : '+968';
        const mobileNumber = mobile_number || '';
        
        return vendors ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Email : {email}</div>
            <div>Mobile : {countryCode}{mobileNumber}</div>
          </div>
        ) : '-';
      }
    },
    {
      title: `${lang("Customer")} ${lang("Detail")}`,
      dataIndex: "users",
      key: "users",
      width: 280, // Width 200px
      render: ( users ) => {
        const { name, email, country_code, mobile_number } = users || {};
        const countryCode = country_code ? `+${country_code}-` : '+968';
        const mobileNumber = mobile_number || '';
        
        return users ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Email : {email}</div>
            <div>Mobile : {countryCode}{mobileNumber}</div>
          </div>
        ) : '-';
      }
    },
    {
      title: `${lang("Venue")} ${lang("Name")}`,
      dataIndex: "venue",
      key: "venue",
      width: 280,
      render: ( venue ) => {
        const { name, location } = venue || {};
      
        return venue ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Location : {location}</div>
          </div>
        ) : '-';
      }
    },
    {
      title: `${lang("Event")} ${lang("Name")}`,
      dataIndex: "event",
      width: 230,
      key: "event",
      render: (_, { events }) => {
        const { name, timings, tag, date } = events || {};

        return events ? (
          <div className="min-font-bold">
            <div>Name : {name}</div>
            <div>Timings : {timings}</div>
            <div>Date : {date}</div>
            <div>Tag : {tag}</div>

          </div>
        ) : '-';
      },
    },
    {
      title: `${lang("Amount")} ($)`,
      dataIndex: "amount",
      key: "amount",
      render: (_, { amount }) => {
        return amount ? amount : "-";
      },
    },
    {
      title: `${lang("Payment")} ${lang("Status")}`,
      key: "payment_status",
      render: (_, { payment_status }) => {
        return (
          <>
            {payment_status === "success" ? (
              <Badge colorSuccess status="success" text="Paid" />
            ) : (
              <Badge status="yellow" text="Pending" />
            )}
          </>
        );
      },
    },
    {
      title: `${lang("Transaction_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("MM-DD-YYYY");
      },
    },
  ];
  
  const revenueMonthlyCount = (data) => {
    const Cmonths = data?.revenueGraph?.revenueMonthlyCount?.map(item => item?.month);
    const Ccounts = data?.revenueGraph?.revenueMonthlyCount?.map(item => item?.count);
    const revenueChartData = {
      labels: Cmonths,
      datasets: [
        {
          label: `${lang("Revenue")} ${lang("Monthly")}`,
          data: Ccounts,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 1,
        },
      ],
    };
    setGraphRevenueData(revenueChartData)
  }

  const revenueWeeklyCount = () => {
    const Cmonths = dashboard?.revenueGraph?.revenueWeeklyCount?.map(item => item?.week);
    const Ccounts = dashboard?.revenueGraph?.revenueWeeklyCount?.map(item => item?.count);
    const revenueChartData = {
      labels: Cmonths,
      datasets: [
        {
          label: `${lang("Revenue")} ${lang("Weekly")}`,
          data: Ccounts,
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgb(255, 159, 64)',
          borderWidth: 1,
        },
      ],
    };
    setGraphRevenueData(revenueChartData)
  }

  const revenueYearlyCount = () => {
    const Cmonths = dashboard?.revenueGraph?.revenueYearlyCount?.map(item => item?.year);
    const Ccounts = dashboard?.revenueGraph?.revenueYearlyCount?.map(item => item?.count);
    const revenueChartData = {
      labels: Cmonths,
      datasets: [
        {
          label: `${lang("Revenue")} ${lang("Yearly")}`,
          data: Ccounts,
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgb(255, 205, 86)',
          borderWidth: 1,
        },
      ],
    };
    setGraphRevenueData(revenueChartData)
  }

 

  useEffect(() => {
    setLoading(true)
    request({
      url: apiPath.dashboard,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setDashboard(data.data);
        revenueMonthlyCount(data?.data);

        if (data.data.transactionData && data.data.transactionData.length > 0) {
          setRecentTransaction(data.data.transactionData);
        }
        if (data.data.userData && data.data.userData.length > 0) {
          setRecentUser(data.data.userData);
        }
       
        if (!!data.data.earning) {
          setEarning(data.data.earning);
        }

        setTimeout(() => {
          setBarData(true);
        }, 1000);
      },

      onError: (error) => {
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }, [refresh])

  return (
    <>
      <div className="layout-content">
        <div className="mb-24">
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24}>
              <Row className="rowgap-vbox" gutter={[24, 16]} style={{ marginLeft: "0" }}>
                {count.map((c, index) => (
                  <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6} className="mb24" style={{ paddingRight: "0px" }}>
                    <Link to={c.url}>
                      <Card bordered={false} className="criclebox" style={{ height: "100%" }}>
                        <div className="number">
                          <Row align="middle" gutter={[24, 0]}>
                            <Col xs={18}>
                              <span>{c.today}</span>
                              <Title level={3}>{c.title}</Title>
                            </Col>
                            <Col xs={6}> <div className="icon-box">{c.icon}</div></Col>
                          </Row>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>

        <Row gutter={[24, 16]}>
         

          {/* <Col span={12}>
            <Card bordered={false} className="criclebox tablespace" style={{ marginBottom: '16px' }}>
              <p className="dashboard-table-heading">{lang("Least_Selling_Product")}</p>
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={productColumn}
                  dataSource={recentTransaction}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col> */}

        </Row>

        <Row gutter={[24, 16]}>

          <Col xs={24} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full ">
              <Title level={5}>{lang("Revenue")} ($)</Title>
              <Row style={{ justifyContent: "end" }} >
                <Radio.Group defaultValue="month" buttonStyle="solid" >
                  <Radio.Button className="chart-btn" onClick={revenueWeeklyCount} value="week">{lang("Week")}</Radio.Button>
                  <Radio.Button className="chart-btn" onClick={() => revenueMonthlyCount(dashboard)} value="month">{lang("Month")}</Radio.Button>
                  {/* <Radio.Button className="chart-btn" onClick={revenueYearlyCount} value="month">{lang("Last") + ' ' + lang("Month")}</Radio.Button> */}
                  <Radio.Button className="chart-btn" onClick={revenueYearlyCount} value="year">{lang("Last") + ' ' + lang("Year")}</Radio.Button>
                </Radio.Group>
              </Row>
              {loading ? [1, 2, 3].map(item => <Skeleton active key={item} />) : <>{barData && <Bar data={revenueGraphData} options={options} />}</>}
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Card bordered={false} className="criclebox tablespace" style={{ marginBottom: '16px' }}>
              <p className="dashboard-table-heading">{lang("Recent_Transactions")}</p>
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={transactionColumn}
                  dataSource={recentTransaction}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
          <Col span={24}>
            <Card bordered={false} className="criclebox tablespace" style={{ marginBottom: '16px' }}>
              <p className="dashboard-table-heading">{lang("Recent Users")}</p>
              <div className="table-responsive">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={recentUser}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        
         
        </Row>

      </div>
    </>
  );
}

export default Home;
