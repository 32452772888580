import { Row, Col, Card, Table, Button, Image, Modal, Form, Input, Skeleton, Select, Divider } from "antd";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/lang";

function Edit() {

  const sectionName = lang("Setting");
  const routeName = "setting";

  const api = {
    edit: apiPath.editSetting,
    get: apiPath.viewSetting,
    userList: apiPath.allUserList,
  };

  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  
  const fetchData = () => {
    setLoading(true);
    request({
      url: api.get,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
      
        form.setFieldsValue({ ...data.data });
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

 

  const onSubmit = (values) => {
    const payload = values

    request({
      url: api.edit,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Card title={"Admin Commission"}>
        {loading ? (
          [1, 2, 3, 4].map((item) => <Skeleton active key={item} />)
        ) : (
          <Form
            className="edit-page-wrap"
            form={form}
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
            name="setting_form"
          >
            <Row gutter={[24, 0]}>
             
              <Col span={24} xs={24} sm={12} lg={12} xxl={6}>
                <Form.Item
                  label="Admin commission for event tickets"
                  name="ticketCommission"
            
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter a valid number!",
                    },
                  ]}
                >
                  <Input
                    maxLength={100}
                    autoComplete="off"
                    placeholder="Enter ticket commission"
                  />
                </Form.Item>
              </Col>

              <Col span={24} xs={24} sm={12} lg={12} xxl={6}>
                <Form.Item
                  label="Table Commission(%)"
                  name="tableCommission"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter a valid number!",
                    },
                  ]}
                >
                  <Input
                    maxLength={100}
                    autoComplete="off"
                    placeholder="Enter Table commission"
                  />
                </Form.Item>
              </Col>
              <Col span={24} xs={24} sm={12} lg={12} xxl={5}>
                <Form.Item
                  label="Commission for female guestlist"
                  name="guestListFemaleCommission"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter a valid number!",
                    },]}
                >
                  <Input
                    maxLength={80}
                    autoComplete="off"
                    placeholder="Enter Commission"
                  />
                </Form.Item>
              </Col>

              <Col span={24} xs={24} sm={12} lg={12} xxl={5}>
                <Form.Item
                  label="Commission for male guestlist"
                  name="guestListMaleCommission"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter a valid number!",
                    },
                  ]}
                >
                  <Input
                    maxLength={80}
                    autoComplete="off"
                    placeholder="Enter Commission"
                  />
                </Form.Item>
              </Col>
              <Col span={24} xs={24} sm={12} lg={12} xxl={5}>
                <Form.Item
                  label="Android App Link"
                  name="androidAppLink"
                  rules={[
                    {
                      pattern: /^(https?:\/\/)?([\w.-]+)+(\.[\w\.-]+)+([\/\w\.-]*)*\/?(\?[\w=&.-]*)?$/,
                      message: "Please enter a valid link!",
                    },]}
                >
                  <Input
                    maxLength={80}
                    autoComplete="off"
                    placeholder="Enter url"
                  />
                </Form.Item>
              </Col>

              <Col span={24} xs={24} sm={12} lg={12} xxl={5}>
                <Form.Item
                  label="IOS App Link"
                  name="iosAppLink"
                  rules={[
                    {
                      pattern: /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,6})(\/[a-zA-Z0-9.-]*)*\/?$/,
                      message: "Please enter a valid link!",
                    },
                  ]}
                >
                  <Input
                    maxLength={80}
                    autoComplete="off"
                    placeholder="Enter url"
                  />
                </Form.Item>
              </Col>


            </Row>

            <Form.Item className="btn-row float-right">
              <Button type="primary" className=" mb-1" loading={loading} htmlType="submit"> {lang("Update") + " " + lang("Setting")} </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </>
  );
}

export default Edit;