import { Row, Col, Card, Table, Button, Input, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import { useNavigate } from "react-router";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import ConfirmationBox from "../../components/ConfirmationBox";
import moment from 'moment';
import lang from "../../helper/lang";
const Search = Input.Search;

function Index() {
  const userProfile = JSON.parse(localStorage.getItem("userProfile"))
  const permission= userProfile.user_type== "SubAdmin"?userProfile.permission.EmailSection:undefined;

  const sectionName = lang("Email_Template");
  const routeName = "email-template";

  const api = {
    status: apiPath.statusEmailTemplate,
    list: apiPath.listEmailTemplate
  }

  const [searchText, setSearchText] = useState('');
  const { request } = useRequest()
  const { showConfirm } = ConfirmationBox()
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const view = (id) => {
    navigate(`/${routeName}/view/${id}`)
  }

  const edit = (id) => {
    navigate(`/${routeName}/update/${id}`)
  }

  const columns = [
    {
      title: `${lang("Title")}`,
      dataIndex: "title",
      key: "title",
    },
    {
      title: `${lang("Subject")}`,
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: `${lang("Created_On")}`,
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return (
          moment(created_at).format('DD-MMM-YYYY')
        );
      },
    },
    ...(userProfile.user_type=="Admin" || permission.includes("view") ?
    [{
     title: `${lang("Action")}`,
      fixed: 'right',
      render: (_, record) => {
        return (
          <>
            {userProfile.user_type=="Admin" || permission.includes("edit") ?<Tooltip title={`${lang("Update")} ` + sectionName} color={"purple"} key={"updateemail"}><Button onClick={() => { edit(record._id) }}><i className="fa fa-light fa-pen"></i></Button></Tooltip>:''}
            <Tooltip title={`${lang("View")} ` + sectionName} color={"purple"} key={"viewemail"}><Button onClick={() => { view(record._id) }}><i className="fa fa-light fa-eye"></i></Button></Tooltip>
          </>
        );
      },}]:[])

  ];

  useEffect(() => {
    setLoading(true)
    fetchData(pagination)
  }, [refresh, debouncedSearchText])


  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null

    request({
      url: api.list + `?status=${filterActive ? filterActive.join(',') : ''}&page=${pagination ? pagination.current : 1}&limit=${pagination ? pagination.pageSize : 10}&search=${debouncedSearchText}`,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data.data.list.docs)
        setPagination(prev => ({ current: pagination.current, total: data.data.list.totalDocs }))
      },
      onError: (error) => {
        console.log(error)
        setLoading(false)
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  const handleChange = (pagination, filters) => {
    console.log(pagination, filters);
    fetchData(pagination, filters);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value)
    setPagination({ current: 1 })
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={sectionName + ` ${lang("Manager")}`}
              extra={
                <>
                  <Search
                    allowClear
                    size="large"
                    onChange={onSearch}
                    value={searchText}
                    onPressEnter={onSearch}
                    placeholder={`${lang("Search")} ${lang("By")} ${lang("Title")}`}
                  />
                </>
              }
            >

              <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4>
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{ defaultPageSize: 10, responsive: true, total: pagination.total, showSizeChanger: true,  pageSizeOptions: ['10', '20', '30', '50'] }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
